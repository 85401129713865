<template>
  <div class="min-h-screen">
    <StoresCategorySlider
      v-if="route.query.store_type_id && store.categories?.length"
      :categories="store.categories"
      :enable-navigate="true"
      class="mt-5"
      @go-route="goToMaterials"
    />
    <div class="relative mt-5" style="min-height: 50px">
      <div
        class="sela-title absolute whitespace-nowrap left-1/2 -translate-x-1/2"
      >
        <span>{{ t('stores') }}</span>
      </div>
    </div>
    <StoresGrid
      class="sela-container mb-5"
      :enable-cache="true"
      :fetch-params="{ orderBy: 'id' }"
      :with-query-params="true"
    />
  </div>
</template>

<script setup lang="ts">
import { useCategoriesStore } from '~/store/categories'
const { url } = useDomainHost()
const route = useRoute()
const store = useCategoriesStore()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(
    url + '/stores',
    t('stores'),
    t('sila_description'),
    url + '/images/sela.webp'
  )
)
function goToMaterials(id?: number) {
  const localePath = useLocalePath()
  useRouter().push(
    localePath({
      name: 'materials',
      query: {
        ...route.query,
        category_id: id ? id.toString() : undefined
      }
    })
  )
}
useHead({
  title: t('stores'),
  link: [useSeoCanonical(url + '/stores')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('stores'), url + '/stores')
    ])
  ]
})
</script>
